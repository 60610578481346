import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { N200 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import {
	GeneralShortcutListener,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
} from '@confluence/shortcuts';

import { InlineHeaderButton, NavigationContainer } from './styled-components';

const i18n = defineMessages({
	nextCommentLabel: {
		id: 'inline-comments-common.next-comment-label',
		description: 'Label displayed for the Next comment',
		defaultMessage: 'Next Comment',
	},
	previousCommentLabel: {
		id: 'inline-comments-common.previous-comment-label',
		description: 'Label displayed for the Previous comment',
		defaultMessage: 'Previous Comment',
	},
	nextCommentToolTip: {
		id: 'inline-comments-common.next-comment-tooltip',
		description: 'ToolTip displayed for the Next comment',
		defaultMessage: 'Next comment (N)',
	},
	previousCommentToolTip: {
		id: 'inline-comments-common.previous-comment-tooltip',
		description: 'ToolTip displayed for the Previous comment',
		defaultMessage: 'Previous comment (P)',
	},
});

type CommentNavigationProps = {
	currentCommentIndex?: number;
	commentsCount?: number;
	isSSRFocusedComment?: boolean;
	allowShortcuts?: boolean;
	goToNextInlineComment: () => void;
	goToPrevInlineComment: () => void;
};

export const CommentNavigation: FC<CommentNavigationProps> = ({
	currentCommentIndex,
	commentsCount,
	isSSRFocusedComment,
	goToNextInlineComment,
	goToPrevInlineComment,
	allowShortcuts = true,
}) => {
	const { formatMessage } = useIntl();

	const noop = () => {};
	const renderCommentIndexAndCounts = () => {
		if (currentCommentIndex === -1) {
			return null;
		}
		/* doing typecheck instead of if(currentCommentIndex && commentsCount) since
    currentCommentIndex can be 0 */
		if (typeof currentCommentIndex === 'number' && typeof commentsCount === 'number') {
			return (
				<span data-testid="comment-index-and-counts">{`${
					currentCommentIndex + 1
				} of ${commentsCount}`}</span>
			);
		} else {
			return null;
		}
	};

	const navigationListener = (event: KeyboardEvent) => {
		if (!event) return;
		if (event.key === NEXT_COMMENT_SHORTCUT) {
			goToNextInlineComment();
		} else if (event.key === PREV_COMMENT_SHORTCUT) {
			goToPrevInlineComment();
		}
	};

	return (
		<NavigationContainer data-testid="comment-navigation-container">
			<Tooltip
				content={
					allowShortcuts ? (
						<FormattedMessage {...i18n.previousCommentToolTip} />
					) : (
						<FormattedMessage {...i18n.previousCommentLabel} />
					)
				}
				hideTooltipOnClick
				position="top"
			>
				<InlineHeaderButton
					isSSRFocusedComment={isSSRFocusedComment}
					data-cy="inline-nav-previous"
					testId="comment-navigation-prev"
					iconBefore={
						<ChevronUpIcon
							label={formatMessage(i18n.previousCommentLabel)}
							primaryColor={token('color.icon.subtle', N200)}
							size="medium"
						/>
					}
					onClick={!isSSRFocusedComment ? goToPrevInlineComment : noop}
				/>
			</Tooltip>
			<Tooltip
				content={
					allowShortcuts ? (
						<FormattedMessage {...i18n.nextCommentToolTip} />
					) : (
						<FormattedMessage {...i18n.nextCommentLabel} />
					)
				}
				hideTooltipOnClick
				position="top"
			>
				<InlineHeaderButton
					isSSRFocusedComment={isSSRFocusedComment}
					data-cy="inline-nav-next"
					testId="comment-navigation-next"
					iconBefore={
						<ChevronDownIcon
							label={formatMessage(i18n.nextCommentLabel)}
							primaryColor={token('color.icon.subtle', N200)}
							size="medium"
						/>
					}
					onClick={!isSSRFocusedComment ? goToNextInlineComment : noop}
				/>
			</Tooltip>
			{allowShortcuts && (
				<GeneralShortcutListener
					accelerator={[NEXT_COMMENT_SHORTCUT, PREV_COMMENT_SHORTCUT]}
					listener={(event) => navigationListener(event)}
				/>
			)}
			{process.env.REACT_SSR ? <span id="inline-comment-index" /> : renderCommentIndexAndCounts()}
		</NavigationContainer>
	);
};
